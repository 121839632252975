@import "../../../../stylesheets/global.scss";

.indication {
    pointer-events: none; 
    position: fixed;
    bottom: -22.5rem;
    z-index: 10;
    .textAnimation {
        display: inline-block;
        padding-left: 5px;
        padding-right: 5px;
        background: $black;
    }

    &.black {
        color: black;
    }

    &.white {
        color: white;
    }

    &--left {
        left: 5.5vw;
        text-align: left;

        .indication__title__arrow {
            right: calc(100% + 1.5rem);
        }

        .indication__description {

        }
    }

    &--right {
        right: 5.5vw;
        text-align: right;
        
        .indication__title__arrow {
            left: calc(100% + 1.5rem);
        }

        .indication__description {
            margin-left: auto; 
        }
    }

    &__title {
        position: relative;
        margin-bottom: .2rem;
        text-transform: uppercase;
        font-size: 3rem;
        font-variation-settings: $bold;
        mix-blend-mode: difference;
        height: 1.3em;
        
        &__arrow {
            position: absolute;
            top: 50%;
            transform: translateY(-60%);
            font-size: 3rem;

            .arrow{
                visibility: hidden;
                font-variation-settings: $medium;
                &:first-child{
                    font-variation-settings: $bold;
                }
                &:last-child{
                    font-variation-settings: $light;
                }
            }
        }
    }

    &__description {
        background: $black;
        margin-bottom: 2.5rem;
        margin-top: 0;
        max-width: 50rem;
        font-size: 2.4rem;
        width: 100%;
        font-variation-settings: $light;
        mix-blend-mode: difference;
        // animation
        opacity: 0;
        transform: translateY(5px)
    }

    &__iconContainer {
        display: inline-block;
        width:11rem;

        &__icon {
            width: 100%;
        }
    }
    

}