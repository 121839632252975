* {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  font-weight: $medium;
  //font-size: 1vw;
}


body {
  margin: 0;
  padding: 0;
  font-family: $font-scopevariable, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

html,body {
  height: 100%;
  //overflow: hidden;
}

#root {
  &.overlay {
    background-color: $red;
  }
}

.dg {
  position: fixed;
  right: 20px;
  z-index: 2;
}