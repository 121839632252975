@import "../../../../stylesheets/global";

.handle {

  $lottie-container-size: 30rem;

  $handle-drag-width: 7rem;
  $handle-drag-height: $lottie-container-size / 2;

  $handle-drag-target-height: 90%;

  margin: auto;
  position: relative;

  &__drag {
    position: absolute;
    width: $handle-drag-width;
    height: $handle-drag-height;
    background-color: black;
    transform-origin: center bottom;
    top: 0;
    left: $lottie-container-size / 2 - $handle-drag-width / 2;

    &__target {
      background-color: $red;
      width: 100%;
      height: $handle-drag-target-height;
    }
  }


  &__lottie-container {
    position: relative;
    width: $lottie-container-size;
    height: $lottie-container-size;

    .lottie-animation {
    }

    svg.mobile-handle-center {
      position: absolute;
      width: $lottie-container-size;
      height: $lottie-container-size;
      top: 0;
      left: 0;
      pointer-events: none;
      transform: scale(1.03);
    }
  }







}
