@import "../../../../stylesheets/global";

.intro-step {
  opacity: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  position: relative;
  z-index: 1;

  &__lang {
    position: absolute;
    top: 1.9rem;
    right: .8rem;
    font-size: 1.8rem;
    color: #000;
    text-decoration: none;
    display: block;
    margin-bottom: .8rem;
    line-height: 1em;
    > span, a {
      padding: .9rem 1.1rem;
      color:#000;
      border: 1px solid #000;
      text-decoration: none;
    }
    .active {
      background:#000;
      color:#fff;
    }
  }

  &__infos {
    overflow: hidden;
    position: relative;
    height: 83vh;
    width: 100%;
    padding-top: 3rem;
    padding-left:6.66%; 
    padding-right:13.33%; 
    background: $white;
    text-align-last: left;

    video {
      position: relative;
      left: -0.6rem;

      &::-internal-media-controls-overlay-cast-button {
        display: none;
      }
    }

    &__logo {
      width: 100%;
    }

    &__paragraph {
      color: $black;
      text-align: left;
      font-size: 2.2em;
      margin-top: 0;
      margin-bottom: 3.75rem;
      font-variation-settings: $light;

      &:last-child {
       margin-bottom: 0;
      }
    }

    &__viewmore {
      position: absolute;
      bottom: 4.4rem;
      text-align: center;
      font-size: 2.2rem;
      font-variation-settings: $medium;
      color: $black;
      left: 50%;
      transform: translateX(-50%);
    }

    &__viewmoreArrow {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%) translateY(60%);
      width: 11vw;
    }

    &__arrow {
      fill: $white;
    }
  }

  &__form {
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    //margin-top: 24.3vw;
    padding-top: 24.3vw;

    &__viewmoreArrow {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%) translateY(-40%);
      width: 11vw;
    }

    &__numbers {
      display: flex;
      width: 100%;
      justify-content: space-evenly;
      font-variation-settings: "wght" 60, "wdth" 600;

      p {
        height: 4.8rem;
        width: 5rem;
        margin: 0 0.8rem 1.3rem 0.8rem;
        padding-bottom: 0.5rem;
        color: $white;
        border-bottom: 1px solid $white;
        text-align: center;
        font-size: 3.5rem;
        font-variation-settings: "wght" 60, "wdth" 600;
        font-family: $font-scopevariable;

        &.error {
          color: $red;
        }
      }
    }
  }

}