@import "../../../../stylesheets/global";

.analysis-step {
  height: 100%;
  width: 100%;
  background-color:$black;
  color: $white;
  font-size: 2.2rem;
  display: flex;

  &__text {
    margin: auto;
    font-size: 4rem;
  }

  &__video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0;
  }
}