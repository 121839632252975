@import "../../../../stylesheets/global";

.keyboard {

  &__board {
    width: calc(74vw + 2px);
    position: relative;
    border: 1px white solid;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    &__key {
      position: relative;
      width: calc(100% / 3);
      height: 17.1vw;
      background-color: $black;
      color: $white;
      border: 1px solid rgba(255, 255, 255, 0.31);
      font-variation-settings: "wght" 60, "wdth" 600;
      //transition: all 0.2s ease;

      &:active {
        color: $red;
        background-color:$white;
      }

      p {
        position: absolute;
        top: 50%;
        left: 50%;
        margin: 0;
        font-size: 3.4rem;
        transform: translateX(-50%) translateY(-50%);
      }

      &_delete {
        svg {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
          width:40%;
          fill:$white;
          stroke: $white;
        }

        &:active {
          svg {
            fill: $red;
            stroke: $red;
          }
        }
      }
    }
  }

  &__button {
    font-family: $font-scopevariable;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    width: 100%;
    height: 18.5vw;
    border: 0;
    font-size: 2.5rem;
    font-variation-settings: "wght" 60, "wdth" 600;
    outline: none;
    background: $red;
    color: black;
    opacity: 0.6;

    &.active {
      background: $red;
      opacity: 1;

      &:active {
        background: $white;
        color: $red;
      }
    }


  }
}