@import "../../../../../../stylesheets/global";

.block-appear {
    // margin-left: 20px;
    opacity: 0;
    &.active {
        opacity: 1;
    }

    &__item {
        color: $white;
        font-size: 2.2rem;
        margin: 0;
        font-variation-settings: $light;

        &.uppercase {
            text-transform: uppercase;
        }
    }

    &__value {
        background : $white;
        color: $black;
        clip-path: inset(0px 100% 0px 0px);
        transition: clip-path .3s ease-in-out; 
        font-size: 1.8rem;
        padding-left: .3rem;
        padding-right: .3rem;
        margin-left: 1rem;
        &.active {
            clip-path: inset(0px 0px 0px 0px);
        }
    }

    &__container {
        display: flex;
        align-items: center;
    }

    .hide {
        opacity: 0;
    }

    .show {
        opacity: 1;
    }

    .block {
        display: flex;
        align-items: center;

        &--player {
            .block-appear__item {
                margin-left: 0 !important;
            }

            .block-appear__value{
                margin-left: .3rem;
                margin-right: .3rem;
            }
        }
    }
}


