@import "../../../../stylesheets/global";

.scene-step {
  //override step style
  &.step {
    width: 100%;
  }

}

