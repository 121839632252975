@import "../../../../../../stylesheets/global";

.text-analysis {
    position: absolute;
    top: 30px;
    left: 30px;

    .block--player__wrapper {
        display: flex;
        width: 50%;
        justify-content: space-between;

        .ndisplay {
            visibility: hidden;
        }
    }
    &--right {
        left: auto;
        right:30px;
        text-align: right;

        .block .block-appear__item{
            margin-left: auto;
        }

        .block--player {
            justify-content: flex-end;
        }
    }
}