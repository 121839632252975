@import "../../../../stylesheets/global";

.conclusion-step {
  opacity: 1;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
  width: 100%;
  display: flex;
  z-index: 1;
  overflow: hidden;

  &__cards {
    position: absolute;
    height:100vh;
    width: 100%;

    &__card {
      height: 100vh;
      position: absolute;
      left: 0;
      width: 100%;
      transform: translateY(100vh);
      padding: 5rem 3.5rem;

      &:nth-of-type(4n+1) {
        background-color: $red;
      }
      &:nth-of-type(4n+2) {
        background-color: black;
      }
      &:nth-of-type(4n+3) {
        background-color: white;
        p, span {
          color: black;
        }
      }
      &:nth-of-type(4n+4) {
        background-color: black;
      }

      p {
        margin: 0;
        font-size: 2.4rem;
        color: white;
        font-variation-settings: $medium;

        span {
          font-size: 1.5rem;
          line-height: 2.5rem;
          display: inline-block;
        }

        &.source {
          text-align: right;
          margin-top: 3rem;
        }
      }

      .wrap {
        position: relative;
        height: 100%;
        width: 100%;

        a {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translateX(-50%) translateY(-50%);
          margin: 0 auto;
          padding: 2rem;
          display: flex;
          background-color: $red;
          color: black;
          text-decoration: none;

          p {
            color: black;
            margin: auto;
          }
        }
      }

    }
    &__number {
      color:$white;
      position: absolute;
      bottom: 25vh;
      right: 5rem;
      font-size: 1.6rem;
    }
  }

}