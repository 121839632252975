.grid {
  position: fixed;
  height: 100vh;
  width: 100vw;
  display: grid;
  grid-template-columns: 13vw repeat(4, 18.5vw) 13vw;
  grid-template-rows: repeat(4, 18.5vw);
  opacity: 0.2;

  div {
    border-bottom: 1px dashed rgba(255, 255, 255, 0.5);
    border-right: 1px dashed rgba(255, 255, 255, 0.5);

    &:nth-child(2n + 1) {
      border-right: 1px dashed rgba(255, 255, 255, 1);
    }

    @for $i from 1 to 60 {
      @if (floor(($i - 1)/6) % 2) == 0 {
        &:nth-child(#{$i}) {
          border-bottom: 1px dashed white;
        }
      }
    }
  }
}