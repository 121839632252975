@import "../../../../stylesheets/global";

.cursor-step {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  //pointer-events: none;

  .power-button {
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    bottom: 18.87%;
    width: 56.66vw;
    height: 56.66vw;
    border-radius: 50%;
    background-color:$red;
    font-size: 9.5rem;
    font-variation-settings: "wght" 60, "wdth" 600;
    
    &--active {
      color:$red;
      background-color: $white;  
    }

    &__container {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__text {
      margin-top: -6vw;
      pointer-events: none;
    }

    &:focus {

    }
  }

  // & > {
    // p {
    //   color: #91ff9e;
    // }

    .cursor {
      opacity: 0;
      visibility: hidden;
      position: absolute;
      border-radius: 50%;
      background-color: rgba(255, 84, 77, 0.71);
      top: calc(50% - 30px);
      left: calc(50% - 30px);
      width: 60px;
      height: 60px;
    }


  // }
}