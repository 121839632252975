@import "../../../../stylesheets/global";

.fingerprint-step {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;

  &__button {
    margin: auto;
    background-color: $red;
    width: 13rem;
    height: 18rem;
    top: 8rem;
    border-radius: 6rem;
    position: relative;
    overflow: hidden;
    transform: translate3d(0, 0, 0);

    &__progression {
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      bottom: 0;
      transform: translateY(100%);
      transform-origin: bottom;
      background-color: white;
      background-position: 50% 50%;
      background-size: 8rem;
    }

    svg {
      position: absolute;
      height: 70%;
      top: 50%;
      left:50%;
      transform: translateX(-50%) translateY(-50%);
    }
  }
}
