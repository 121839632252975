@import "../../stylesheets/global";


.mobile-app {
  background-color: black;
  min-height: 100vh;
  max-height: 100vh;
  overflow: hidden;
  user-select: none;
  position: relative;

  
  & > {
    .dev-toggle {
      // display: none;
      opacity: 0;
      color: green;
      z-index: 100;
      position: absolute;
      margin: 0;
      top: 0;
      left: 10px;
      font-size: 2rem;
      font-variation-settings: $bold;
      cursor: pointer;
    }

    .dev-button {
      display: none;
      background: rgba(0, 0, 0, 0.75);

      &.show {
        display: flex;
        // display: none;
      }

      position: absolute;
      flex-direction: column;
      top: 3rem;
      left: 10px;
      z-index: 100;

      p {
        color: white;
        margin: 0.7rem 1.5rem;
        font-size: 2rem;
        cursor: pointer;
      }
    }

  }

}

