@import "../../../../stylesheets/global";

.conclusion-step {
    .hide {
        opacity: 0;
    }
    .show {
        opacity: 1;
    }
    &__substep {
        position: absolute;
        height: 100vh;
        width: 100vw;
        background:$red;
        display: flex;
        align-items: center;
        justify-content: center;
        // visibility: hidden;
        opacity: 0;
        
        &__wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
        } 

        &__text {
            opacity: 0;
            font-size: 3.3rem;
            font-variation-settings: $light;

            &.show {
                opacity: 1;
            }

            &--title {
                font-size: 4.5rem;
                font-variation-settings: $light;
                // font-variation-settings: $medium;
            }
            &--white {
                color: $white;
            }

            &--red {
                color: $red;
            }
        }

        &__score {
            opacity: 0;
            background:$black;
            width: 100%;
            color:$white;
            text-align: center;
            padding-top: 0.2rem;
            padding-bottom: 0.2rem;
            margin-top: 7rem;
            min-height: 30px;
            // height: 0.2rem;

            .textAnimation {
                opacity: 1;
            }

            &.show{
                opacity: 1;
            }
        }

        &__icon {
            width: 6.7%;
            position: absolute;
            bottom: 0;
            transform: translateY(100%);
            transition:transform .3s ease-in;

            &.show {
                transform: translateY(40%);
            }
        }

       &__wrapperDescription {
                display: flex;
            justify-content: space-between;
            width: 100%;
       }

        &.is-active{
            opacity: 1;
            // visibility: visible;
        }

        &--1 {

            .conclusion-step__substep__text--white {
                margin: 0;
                display: flex;
                justify-content: space-between;
                width: 19.4vw;
                &.text-over {
                    display: flex;
                    justify-content: space-between;
                    width: 19.4vw;
                    position: absolute;
                    color: $black;

                    &.hide{
                        opacity: 0;
                    }
                }
            }

            &__en {
                .conclusion-step__substep__text--white {
                    width: 12vw;
                    &.text-over{
                        width: 12vw;
                    }
                }
            }
        }

        &--2 {
            .conclusion-step__substep__wrapper {
                width: 17.6vw;
            }

            .conclusion-step__substep__text {
                margin: 0;
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;

                &:first-child {
                    width: auto;
                }

                &.textAnimation{
                    opacity: 1;
                }
            }

            .conclusion-step__substep__text--white {
                display: flex;
                width: 100%;
                justify-content: space-between;
                margin-top: 2rem;
                margin-bottom: 2rem;
            }
        }

        &--3 {
            .conclusion-step__substep__text {
                margin: 0;
                width: 100%;
                display: flex;
                justify-content: space-between;

                &.show {
                    opacity: 1;
                }

                &:nth-child(2) {
                    display: flex;
                    width: 100%;
                    justify-content: space-between;
                    margin-top: 1rem;
                    margin-bottom: 1rem;
                }
            }

            .conclusion-step__substep__wrapper {
                width: 17.6vw;
            }
        }

        &--4 {
            .conclusion-step__substep__text {
                margin: 0;
                margin-bottom: 1rem;
                width: 100%;
                display: flex;
                justify-content: space-between;
                &:last-child {
                    display: flex;
                    width: 100%;
                    justify-content: space-between;
                    margin-bottom: 0;
                }
            }
            .conclusion-step__substep__wrapper {
                width: 29.16vw;
            }
        }

        &--5 {
            // position: relative;
            z-index: 2;
            display: flex;
            justify-content: space-around;

            .conclusion-step__substep__text.title{
                display: flex;
                width: 100%;
                justify-content: space-between;
                margin-top: 0;
                margin-bottom:5rem; 
            }

            .conclusion-step__substep__wrapper {
                width: 27.29vw;
            }

            .conclusion-step__substep__description {
                width: 100%;
                .largeWord{
                    display: flex;
                    justify-content: space-between;
                }
                .conclusion-step__substep__text {
                    margin: 0;

                    &.operator {
                        display: flex;
                        overflow: auto;
                        max-width: 6.7vw;
                        margin-right: 5px;
                        margin-left: 5px;
                    }

                    &.textAnimation {
                        opacity: 1;
                        height: 30px;
                        display: flex;
                    }
                }
            }

            .conclusion-step__substep__wrapperDescription {
                margin-bottom: 1.3rem;
                &--1 {
                    .first {
                        width: 50%;
                        display: flex;
                        justify-content: space-between;
                    }
                }

                &--2 {
                    .first{
                        width: 34%;
                        line-height: 35px;
                    }
                    .last {
                        width: 45%;
                        display: flex;
                        justify-content: space-between;
                    }
                }

                &--3 {
                    // .first{
                    //     width: 32%;
                    // }
                    .last {
                        width: 54%;
                        display: flex;
                        justify-content: space-between;
                    }
                }
            }
        }

        &--6 {
            // position: relative;
            // z-index: 1;
            display: flex;
            flex-direction: column;
            background: $white;

            .conclusion-step__substep__text {
                margin: 0;
                transition:opacity .2s ease-in; 

                &.show{
                    opacity: 1;
                    transition:opacity .2s ease-in; 
                }
            }

        }
    }
}