@import "../../stylesheets/global";

.desktop-app {
  position: relative;
  background-color: black;
  min-height: 100vh;
  max-height: 100vh;
  overflow: hidden;
  font-variation-settings: $medium;
  user-select: none;

  &.overlay {
    mix-blend-mode: multiply;
  }

  .dev-toggle {
    color: green;
    z-index: 100;
    position: absolute;
    margin: 0;
    top: 0;
    left: 10px;
    font-size: 2.3rem;
    font-variation-settings: $bold;
    cursor: pointer;
    &.hide{
      display: none;
    }
  }

  .dev-button {
    // display: none;
    &.show {
      // display: flex;
      display: none;
    }
    position: absolute;
    flex-direction: column;
    top: 3rem;
    left: 10px;
    z-index: 100;

    p {
      color: $red;
      margin: 4px 0;
      font-size: 2.3rem;
      cursor: pointer;
    }

    hr {
      width: 100%;
      height: 1px;
      border: 0;
      background-color: $red;
    }
  }

}
