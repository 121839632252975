@import "../../../../stylesheets/global";

.code-step {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;

}
