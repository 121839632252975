@import "../../../../stylesheets/global";

.stairs-step {
  z-index: 1;
  display: flex;
  position: absolute;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  &__left, &__right {
    height: 100vh;
    width: 50%;

    &__circle {
      position: absolute;
      bottom: 33%;
      transform: translateX(-50%);
      width: 14rem;
      height: 14rem;
      background-color:$red;
      border-radius: 50%;
      pointer-events: none;
    }
  }

  &__left {
    transform-origin: 0 50%;
    //background-color: rgba(232, 46, 46, 0.25);
    background-color: black;
    &__circle {
      left: 31.5%;
    }
  }

  &__right {
    //background-color: rgba(0, 0, 255, 0.25);
    background-color: black;
    transform-origin: 100% 50%;

    &__circle {
      left: 68.5%;
    }
  }

  &__separator {
    position: absolute;
    top: 0;
    width: 1px;
    height: 100%;
    background-color: #fff;
    transform: translateX(-50%);
  }
}