@import "../../../../stylesheets/global";


.connexion-step {

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: black;
  background-color: white;

  .lottie-animation.home-abstrait {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  &__lang {
    position: absolute;
    right: 4rem;
    top: 5rem;
    font-size: 2.1rem;
    color: #000;
    text-decoration: none;
    display: block;
    margin-bottom: .8rem;
    line-height: 1em;
    > span, a {
      padding: .9rem 1.1rem;
      color:#000;
      border: 1px solid #000;
      text-decoration: none;
      cursor: pointer;
    }
    .active {
      background:#000;
      color:#fff;
      pointer-events: none;
    }
  }

  &__city {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;

    div {
      height: 100%;
      width: 50%;
    }

    &__left, &__right {
      opacity: 0;

      &.connected {
        opacity: 1;
        transition: opacity 1.2s 0.3s ease;
      }
    }


  }

  &__intro {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 75%;
    height: 50vh;
    margin-top: 22vh;
    margin-bottom: auto;
    padding-top: 2rem;
    z-index: 2;
    //mix-blend-mode: darken;

    video {
      opacity: 0;
      margin-bottom: 2rem;
      //mix-blend-mode: darken;
    }

    &__baseline {
      text-align: center;
      display: block;
      font-size: 2.1rem;
      font-variation-settings: $light;

      &__1, &__2{
        height: 1.4em;
      }
    }
    &__fullscreen {
      opacity: 0;
      margin: 0;
      margin-top: 1.3em;
      font-size: 1.5rem;
      font-variation-settings: $light;

      .spacebar {
        border: 1px solid;
        border-radius: 4px;
        padding-left: 3px;
        padding-right: 3px;
        padding-bottom: 2px;
        background-color: white;
      }
    }

    &__start {
      outline: none;
      opacity: 0;
      position: absolute;
      left: 50%;
      margin-top: -1rem;
      transform: translateX(-50%);
      // border: none;
      background: $white;
      border: 1px solid $black;
      width: 16%;
      padding-top: 1.4rem;
      padding-bottom: 1.4rem;
      font-size: 1.95rem;
      text-transform: uppercase;
      font-family: $font-scopevariable;
      font-variation-settings: "wght" 60, "wdth" 600;
      cursor: pointer;
      transition: background-color .2s ease-out, color .2s ease-out;
      &:hover {
        background:$black;
        color:$white;
      }

      &.disabled {
        cursor: auto;
        pointer-events: none;
      }
    }
    &__codes {
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 100%;
      margin-top: 5rem;

      &__instructions {
        margin-top: -4.2rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: 2.4rem;
        text-align: center;
        width: 30vw;
        font-variation-settings: $light;

        &__1 {
          span {
            &:nth-child(8),
            &:nth-child(9),
            &:nth-child(10),
            &:nth-child(11),
            &:nth-child(12),
            &:nth-child(13),
            &:nth-child(14),
            &:nth-child(15),
            &:nth-child(16),
            &:nth-child(17),
            &:nth-child(18),
            &:nth-child(19)
             {
              font-variation-settings: $bold;
            }
          }
        }

        &__2 {
      
          span {
            &:nth-child(8),
            &:nth-child(9),
            &:nth-child(10),
            &:nth-child(11),
            &:nth-child(12),
            &:nth-child(13)
             {
              font-variation-settings: $bold;
            }
          }
          
          &__en{
            span {
              &:nth-child(13),
              &:nth-child(14),
              &:nth-child(15),
              &:nth-child(16),
              &:nth-child(17)
               {
                font-variation-settings: $bold;
              }
            }
          }
        }

        &__1, &__2 {
          height: 1.4em;
        }
      }

      &__player1, &__player2 {
        margin: 0;
        text-align: center;
        width: 25rem;
        

        &.connected &__password {
          //$shadow-color: black;
          //color: white;
          //text-shadow: -1px -1px 0 $shadow-color, 1px -1px 0 $shadow-color, -1px 1px 0 $shadow-color, 1px 1px 0 $shadow-color;
        }

        &__password, &__name {
          width: 100%;
          margin: 0;
        }

        &__password {
          transition: text-shadow 1s ease;
          text-shadow: none;
          font-size: 7rem;
          font-variation-settings: $light;
          height: 1.3em;
        }

        &__name {
          opacity: 1;
          height: 1.3em;
          font-size: 1.8rem;
          font-variation-settings: $light;
        }

        &__status {
          margin-top: 1rem;
          opacity: 0;
          p {
            margin: 0;
            height: 1.3em;
            font-size: 2.8rem;
          }

          &__connected, &__ready {
            color: $red;
          }
        }

      }

    }

  }

  &__icon {
    width: 3.5rem;
    position: fixed;
    right: 4rem;
    bottom: 2.5rem;
    opacity: 0;
    a {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  &__credits {
    opacity: 0;
    position: fixed;
    left: 4rem;
    bottom: 2.5rem;

    a{
      text-decoration: none;
      color: $black;
    }

    p {
      font-size: 1.6rem;
      font-variation-settings: $light;
      margin: 0;
    }
  }


}

