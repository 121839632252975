@import "../../../../stylesheets/global";

@keyframes blinker {
    50% {
      opacity: 0;
    }
  }
.loading {
    position: fixed;
    top:0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    font-size: 15rem;
    text-align: center;
    color:$white;

    &__wrapper {
        position: relative;
        width: 350px;
        margin:0 auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        height: 50vh;
        margin-top: 32vh;
        margin-bottom: auto;
        padding-top: 2rem;
        z-index: 2;
        &__blackout {
            position: relative;
            width: 100%;
        }
        &__sentence{
            text-align: center;
            font-size: 2.1rem;
            font-variation-settings: "wght" 60, "wdth" 500;
            animation: blinker .7s linear infinite;
            margin: 0;
        }
        svg{
            display: inline-block;
            stroke:#fff;
            fill:transparent;
            stroke-width:.5px;
        }
        .clip{
            position: absolute;
            left: 0;
            width: 100% ;
            svg {
                fill:#fff;
            }
        }

        &__logo {
            text-align:center;
            position: absolute;
            bottom: 2rem;
            display:inline-block;
            width: 100%;
            svg{
                fill:#fff;
                width: 5.2rem;
                max-width: 45px;
            }
        }

       
    }
}