@import "../../../../stylesheets/global";

.notification-step {
    position: relative;
    width: 74vw;
    margin:0 auto;
    opacity: 0;
    &__title {
        display: block;
        margin-top: 19.5vw;
        position: relative;
        font-size: 4.5rem;
        text-transform: uppercase;
        color:$white;
        font-variation-settings: $bold;
        text-align: center;
    }

    &__subtitle {
        font-variation-settings: $bold;
        font-size: 2.5rem;
        text-align: center;
        color:$white;
        display: block;
    }

    &__icon {
        position: fixed;
        width: 30%;
        left: 50%;
        top: 50%;
        transform: translate3d(-50%, -50%, 0);
        svg{
            width: 100%;
            fill:$red;
        } 
    }
    
    .button{
        position: fixed;
        left: 50%;
        transform: translateX(-50%);
        top:auto;
        width: 74vw;
        bottom: 6.5rem;
        &.isSend {
            opacity: 0;
        }
    }

    .alert {
        position: fixed;
        height: 100vh;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        width: 100vw;
        background: $red;
        opacity: 0;
        z-index: 1;
        pointer-events: none;
    }

}
