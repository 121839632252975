@import "../../../../stylesheets/global";

.scene-manager {
  pointer-events: none;

  &__player1, &__player2 {
    pointer-events: none;
    height: 100%;
    position: fixed;
    display: flex;
    top: 0;
    z-index: 3;
    overflow: hidden;
    // visibility: hidden;
    transform-origin: 0 50%;

    &.door-circle {
      mix-blend-mode: difference;
    }

    &__wrapper {
      pointer-events: none;

      position: absolute;
      width: 100%;
      height: 100%;
      transform-origin: 50% 50%;
      display: flex;

      .lottie-animation {
        .dot path {
        }
        .red path{
          fill: white !important;
        }
      }
    }
  }

  &__player1 {
    // background-color: rgba(20, 200, 170, 0.5);
  }

  &__player2 {
    // background-color: rgba(200, 100, 80, 0.5);
  }

}