@import "../../../../stylesheets/global";

.slider-step {
    position: relative;
    z-index: 1;
    height: 100vh;

    &__wrapper {
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__input {
        display:none;
    }

    &__outer {
        position: relative;
        background:$black;
        height: 59.5vh;
        border:1px solid $white;
        border-bottom:none; 
        display: inline-block;
        width: 40%;
        position: relative;
        user-select: none;
    }

    &__arrow {
        position: absolute;
        width: 30%;
        left: 50%;
        transform: translateX(-50%);
        bottom: 60px;
        svg{
            fill:$white;
        }
    }

    &__lock {
        z-index: 2;
        position: absolute;
        width: calc(100% + 2px);
        height: 50px;
        margin-left: -1px;
        margin-right: -1px;
        background: $red;
        cursor: pointer;
        bottom: 0;
        
        // &.unlocked {
        //     #lock {
        //         cursor: none;
        //     }
        // }
    }
    &__mask {
        background-color: $white;
        background-position: 50% 50%;
        background-size:38px; 
        position: absolute;
        width: calc(100% + 1.5px);
        margin-left: -.5px;
        height: 100%;
        clip-path: inset(100% 0px 0px 0px);
    }
}