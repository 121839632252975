@import "../../../../stylesheets/global";

.cursor {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: black;

  & > {

    .cursor-pointer {
      position: absolute;
      border-radius: 50%;

      top: calc(50% - 30px);
      left: calc(50% - 30px);
      width: 60px;
      height: 60px;

      &.player-1 {
        background-color: rgba(255, 84, 77, 0.70);
      }

      &.player-2 {
        background-color: rgba(118, 252, 255, 0.70);
      }
    }
  }
}

canvas {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  width: 100vw;
  height: 100vh;
}